.terms-title {
    font-family: "Helvetica-Neue-Light";
    font-size: 3.75rem;
    text-align: center;
    margin-bottom: 20px;
}

.terms-subtitle {
    font-family: "Helvetica-Neue-Bold";
    font-size: 1rem;
    text-align: center;
    margin-bottom: 50px;
}
