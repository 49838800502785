@font-face {
  font-family: "Helvetica-Neue-Bold";
  src: url("/fonts/helvetica_neue_bold.eot");
  src: url("/fonts/helvetica_neue_bold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/helvetica_neue_bold.woff") format("woff"),
    url("/fonts/helvetica_neue_bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica-Neue-Medium";
  src: url("/fonts/helvetica_neue_medium.eot");
  src: url("/fonts/helvetica_neue_medium.eot?#iefix") format("embedded-opentype"),
    url("/fonts/helvetica_neue_medium.woff") format("woff"),
    url("/fonts/helvetica_neue_medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica-Neue-Regular";
  src: url("/fonts/helvetica_neue_regular.eot");
  src: url("/fonts/helvetica_neue_regular.eot?#iefix") format("embedded-opentype"),
    url("/fonts/helvetica_neue_regular.woff") format("woff"),
    url("/fonts/helvetica_neue_regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica-Neue-Light";
  src: url("/fonts/helvetica_neue_light.eot");
  src: url("/fonts/helvetica_neue_light.eot?#iefix") format("embedded-opentype"),
    url("/fonts/helvetica_neue_light.woff") format("woff"),
    url("/fonts/helvetica_neue_light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica-Neue-Thin";
  src: url("/fonts/helvetica_neue_thin.eot");
  src: url("/fonts/helvetica_neue_thin.eot?#iefix") format("embedded-opentype"),
    url("/fonts/helvetica_neue_thin.woff") format("woff"),
    url("/fonts/helvetica_neue_thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Helvetica-Neue-Regular", "Helvetica";
  font-weight: normal;
}
