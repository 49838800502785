.app-documentation {
  background: #f7edf0;
  padding-top: 90px;
}

.app-documentation__wrapper {
  width: 100%;
  max-width: 1366px;
  padding: 40px 40px 20px;
  margin: auto;
  color: #B5264F;
}

.app-documentation__text-wrapper {
  display: grid;
  grid-template-rows: 1fr 40px;
  grid-row-gap: 25px;
  justify-content: center;
  text-align: center;
}

.app-documentation__title {
  font-family: "Helvetica-Neue-Light";
  font-size: 40px;
  line-height: 47px;
  padding: 0px 15px;
}

.app-documentation__text {
  padding: 0px 40px;
  font-size: 18px;
  line-height: 21px;
}

.app-documentation__polaris {
  width: 100%;
  max-width: 700px;
  margin: auto;

  * {
    color: unset;
  }

  .Polaris-Card {
    width: 100%;
  }

  .Polaris-List--typeNumber {
    padding-left: 2rem;
  }
}

.app-documentation__contact-button {
  width: 260px;
  max-width: calc(100vw - 3.2rem);
  padding: 12px 0px;
  margin-top: 1.6em;
  color: #B5264F;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 5px;
  text-transform: uppercase;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.5s ease;
  border: 2px solid #B5264F;
  text-align: center;

  &:hover {
      background-color: #B5264F;
      color: #fff;
  }
}

@media screen and (min-width: 1200px) {
  .app-documentation__wrapper {
    padding: 80px 80px 50px;
  }

  .app-documentation__text-wrapper {
    grid-template-rows: 1fr 30px;
    grid-row-gap: 35px;
  }

  .app-documentation__title {
    font-size: 70px;
    line-height: 70px;
  }

  .app-documentation__text {
    font-size: 22px;
    line-height: 30px;
  }
}
