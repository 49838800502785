.terms {
    width: 100%;
    height: auto;
    min-height: calc(100vh - 325px);
    padding-top: 90px;
}

.terms-content {
    max-width: 600px;
    margin: 0px auto;
    margin-bottom: 50px;
    padding-top: 50px;
    font-size: 1.125rem;
}

.terms-content p {
    font-size: 1.125rem;
    margin-bottom: 30px;
}

.terms-content .bold {
    font-family: "Helvetica-Neue-Bold";
}

.terms-content strong {
    display: block;
    font-family: "Helvetica-Neue-Bold";
    font-weight: normal;
    font-size: 1.25rem;
    margin: 30px 0;
}

.terms-content ul li {
    list-style: disc;
    margin-bottom: 10px;
}

.terms-content ul ul {
    margin-left: 20px;
}

.terms-content ul ul>li {
    list-style: circle;
}

.terms-content ol {
    padding-left: 30px;
}

.terms-content ol li {
    margin-bottom: 10px;
}

.terms-head {
    position: relative;
    display: block;
    font-family: "Helvetica-Neue-Bold";
    font-size: 1.25rem;
    text-transform: uppercase;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    margin-bottom: 30px;
}

.terms-select__control {
    box-shadow: none !important;
}

.terms-select__option--is-selected::before {
    content: '✓';
    position: absolute;
    left: 7px;
    color: '#B5264F';
}

@media (min-width: 800px) {
    .terms-head {
        margin-bottom: 40px;
    }
}

@media (min-width: 800px) {
    .terms-head {
        margin-bottom: 50px;
    }
}

.terms-head:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #FF6A8D;
}

ul+.terms-head {
    margin-top: 30px;
}

ol+.terms-head {
    margin-top: 30px;
}

dl+.terms-head {
    margin-top: 30px;
}

.indent {
    padding-left: 20px;
}


dl dd {
    line-height: 1.3;
    margin-bottom: 15px;
}

a[href^="mailto"] {
    color: inherit;
    text-decoration: underline;
}


.highlight {
    color: #FFFFFF;
    background-color: #D14E6F;
    padding: 10px;
    border-radius: 3px;
}
